export const quantities = [
    {
        name: '10',
        id: 1
    },
    {
        name: '50',
        id: 2
    },
    {
        name: '100',
        id: 3
    },
    {
        name: '500',
        id: 4
    },
]
export const shopTableTitles = ["Image", "Name", "Description", "Price", "Quantity"]