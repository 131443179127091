const donate = [
  {
    id: "Group32",
    image: "/Images/donate/Group32.png",
    alt: "Group 32"
  },
  {
    id: "Group33",
    image: "/Images/donate/Group33.png",
    alt: "Group 33"
  },
  {
    id: "Group34",
    image: "/Images/donate/Group34.png",
    alt: "Group 34"
  },
  {
    id: "Group35",
    image: "/Images/donate/Group35.png",
    alt: "Group 35"
  }
];

export default donate;